<template>
  <v-container class="safeMargin">


    <v-card class="mx-auto text-center px-0 pt-0" width="100%" elevation="0">
      <v-card-title class="text-center text-h5 ">
        <v-spacer></v-spacer>


        Hola,


        {{ $store.state.Auth.token.claims.name.split(" ")[0] }}!

        <v-avatar class="ml-2">
          <UserImage :small="true" :user="userImg" />
        </v-avatar>


        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <!--         <v-divider class="mb-8"></v-divider>
 -->
        <WeeklyProgress :daysUsed="workoutsCompleted" :maxDays="user ? user.plan : 7"
          :workedOutToday="workedOutToday" />

      </v-card-text>



    </v-card>



    <v-row class="mt-4" :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col cols="12" sm="6">
        <v-list class="rounded-lg mb-2 pa-0 overflow-hidden ">
          <v-list-item two-line @click="$router.push('/clases')" color="primary darken-2">
            <v-list-item-avatar>
              <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 24 24"
                class="duoicon duoicon-calendar" style="color: var(--v-primary-base)">
                <path fill="currentColor" d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7h18Z"
                  class="duoicon-secondary-layer" opacity=".3"></path>
                <path fill="currentColor"
                  d="M16 3a1 1 0 0 1 1 1v1h2a2 2 0 0 1 2 2v3H3V7a2 2 0 0 1 2-2h2V4a1 1 0 1 1 2 0v1h6V4a1 1 0 0 1 1-1Z"
                  class="duoicon-primary-layer"></path>
              </svg>

            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Horarios</v-list-item-title>
              <v-list-item-subtitle>Reserva tu horario preferido.</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>



        </v-list>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list class="rounded-lg mb-2 pa-0 overflow-hidden">
          <v-list-item two-line @click="$router.push('/cargas')">
            <v-list-item-avatar>
              <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 24 24"
                class="duoicon duoicon-slideshow" style="color: var(--v-primary-base)">
                <path fill="currentColor"
                  d="M21 3a1 1 0 1 1 0 2v11a2 2 0 0 1-2 2h-5.055l2.293 2.293a1 1 0 0 1-1.414 1.414l-2.829-2.828-2.828 2.828a1 1 0 0 1-1.414-1.414L10.046 18H5a2 2 0 0 1-2-2V5a1 1 0 1 1 0-2h18Z"
                  class="duoicon-secondary-layer" opacity=".3"></path>
                <path fill="currentColor"
                  d="M8 11a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-1a1 1 0 0 0-1-1Zm4-2a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0v-3a1 1 0 0 0-1-1Zm4-2a1 1 0 0 0-.993.883L15 8v5a1 1 0 0 0 1.993.117L17 13V8a1 1 0 0 0-1-1Z"
                  class="duoicon-primary-layer"></path>
              </svg>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Controles</v-list-item-title>
              <v-list-item-subtitle>
                Sigue de cerca tu actividad.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list class="rounded-lg mb-2  pa-0 overflow-hidden">

          <v-list-item two-line @click="$router.push('/cronometro')">
            <v-list-item-avatar>
              <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 24 24"
                class="duoicon duoicon-clock" style="color: var(--v-primary-base)">
                <path fill="currentColor"
                  d="M12 4c6.928 0 11.258 7.5 7.794 13.5A8.998 8.998 0 0 1 12 22C5.072 22 .742 14.5 4.206 8.5A8.998 8.998 0 0 1 12 4Z"
                  class="duoicon-secondary-layer" opacity=".3"></path>
                <path fill="currentColor"
                  d="M7.366 2.971A1 1 0 0 1 7 4.337a10.063 10.063 0 0 0-2.729 2.316 1 1 0 1 1-1.544-1.27 12.046 12.046 0 0 1 3.271-2.777 1 1 0 0 1 1.367.365h.001ZM18 2.606a12.044 12.044 0 0 1 3.272 2.776 1 1 0 0 1-1.544 1.27 10.042 10.042 0 0 0-2.729-2.315 1 1 0 0 1 1.002-1.731H18ZM12 8a1 1 0 0 0-.993.883L11 9v3.986c-.003.222.068.44.202.617l.09.104 2.106 2.105a1 1 0 0 0 1.498-1.32l-.084-.094L13 12.586V9a1 1 0 0 0-1-1Z"
                  class="duoicon-primary-layer"></path>
              </svg>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Cronómetro</v-list-item-title>
              <v-list-item-subtitle>
                Mide tu tiempo de entrenamiento.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list class="rounded-lg pa-0 mb-2 overflow-hidden">

          <v-list-item two-line @click="$router.push('/reportar-problema')">
            <v-list-item-avatar>
              <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 24 24"
                class="duoicon duoicon-alert-triangle" style="color: rgb(99, 102, 241);">
                <path fill="currentColor"
                  d="m13.299 3.148 8.634 14.954a1.5 1.5 0 0 1-1.299 2.25H3.366a1.5 1.5 0 0 1-1.299-2.25l8.634-14.954c.577-1 2.02-1 2.598 0Z"
                  class="duoicon-secondary-layer" opacity=".3"></path>
                <path fill="currentColor"
                  d="M12 8a1 1 0 0 0-.993.883L11 9v4a1 1 0 0 0 1.993.117L13 13V9a1 1 0 0 0-1-1Zm0 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
                  class="duoicon-primary-layer"></path>
              </svg>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Reportar un problema
              </v-list-item-title>
              <v-list-item-subtitle>
                ¿Algo no funciona bien?
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6" offset-sm="3" offset-md="4">
        <v-list class="rounded-lg pa-0  overflow-hidden" color="transparent">

          <v-list-item two-line>
            <v-list-item-icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 24 24"
                style="color: var(--v-secondary-base)" class="duoicon duoicon-location">
                <path fill="currentColor"
                  d="M6.72 16.64a1 1 0 1 1 .56 1.92c-.5.146-.86.3-1.091.44.238.143.614.303 1.136.452C8.48 19.782 10.133 20 12 20s3.52-.218 4.675-.548c.523-.149.898-.309 1.136-.452-.23-.14-.59-.294-1.09-.44a1 1 0 0 1 .559-1.92c.668.195 1.28.445 1.75.766.435.299.97.82.97 1.594 0 .783-.548 1.308-.99 1.607-.478.322-1.103.573-1.786.768C15.846 21.77 14 22 12 22s-3.846-.23-5.224-.625c-.683-.195-1.308-.446-1.786-.768-.442-.3-.99-.824-.99-1.607 0-.774.535-1.295.97-1.594.47-.321 1.082-.571 1.75-.766ZM12 7.5c-1.54 0-2.502 1.667-1.732 3 .357.619 1.017 1 1.732 1 1.54 0 2.502-1.667 1.732-3A1.999 1.999 0 0 0 12 7.5Z"
                  class="duoicon-primary-layer"></path>
                <path fill="currentColor"
                  d="M12 2a7.5 7.5 0 0 1 7.5 7.5c0 2.568-1.4 4.656-2.85 6.14a16.353 16.353 0 0 1-1.853 1.615c-.594.446-1.952 1.282-1.952 1.282a1.71 1.71 0 0 1-1.69 0 20.627 20.627 0 0 1-1.952-1.282A16.353 16.353 0 0 1 7.35 15.64C5.9 14.156 4.5 12.068 4.5 9.5A7.5 7.5 0 0 1 12 2Z"
                  class="duoicon-secondary-layer" opacity=".3"></path>
              </svg>
            </v-list-item-icon>
            <v-list-item-content class="text-center">
              <v-list-item-title>
                CENTRO DEPORTIVO SPORTFIT
              </v-list-item-title>
              <v-list-item-subtitle>
                MAGALLANES 1017, MONTEVIDEO
              </v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </v-list>
      </v-col>

      <v-col v-if="$store.state.Auth.token.claims.type && $store.state.Auth.token.claims.type == 'superuser'">
        <v-btn @click="$router.push('/mi-plan')" class="rounded-lg" color="primary" block>
          Mi plan

        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';

import UserImage from '@/components/profile/UserImage.vue';
import WeeklyProgress from '@/components/training/WeeklyProgress.vue';

import {
  getFirestore, collection, query, where, getDocs, doc, getDoc

} from 'firebase/firestore';


export default {
  components: {
    UserImage,
    WeeklyProgress,
  },
  data() {
    return {
      workoutsCompleted: -1,
      user: null,
      alert: false,
      workedOutToday: false,
      userImg: null,
    }
  },

  created() {

    this.userImg = { id: this.$store.state.Auth.token.claims.user_id }
  },

  async mounted() {
    this.$vuetify.goTo(0);
    await this.getUser();
    this.workoutsCompleted = await this.getWorkoutsCompleted(this.$store.state.Auth.token.claims.user_id)

  },
  methods: {
    showData(route) {
      this.$router.push(route);
    },
    async getWorkoutsCompleted(user) {

      const db = getFirestore()
      const workoutsRef = collection(db, `users/${user}/workouts`);
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();

      const q = query(workoutsRef, where("weekStart", ">=", startOfWeek), where("weekStart", "<", endOfWeek));
      const workoutDocs = await getDocs(q);


      if (workoutDocs.empty) {
        return 0
      }
      let workoutDoc = workoutDocs.docs[0]
      let workoutsCompleted = workoutDoc.data().day
      let workoutDates = workoutDoc.data().dates

      let today = moment()
      if (workoutsCompleted > 0 && moment(workoutDates[workoutsCompleted - 1]).isSame(today, 'day')) {
        this.workedOutToday = true
      }


      return workoutsCompleted
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
      return endOfWeek;
    },
    async getUser() {
      const db = getFirestore()
      let user = this.$store.state.Auth.token.claims.user_id;


      const docRef = doc(db, `users/${user}`);
      const docSnap = await getDoc(docRef);

      let data = docSnap.data()

      data.plan = parseInt(data.plan)

      if (data.plan == 0) {
        const db = getFirestore()
        let scheduleIntervalsRef = doc(db, `configurations/schedule`);
        let scheduleIntervals = await getDoc(scheduleIntervalsRef);
        let scheduleIntervalsData = scheduleIntervals.data()
        let maxDays = 0
        scheduleIntervalsData.Domingo.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Lunes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Martes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Miercoles.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Jueves.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Viernes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Sabado.length > 0 ? maxDays += 1 : null

        data.plan = maxDays
      }

      this.user = data

      if (data.disabled) {
        let today = moment().toDate()
        await this.setAlert('user_disabled', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Tu usuario ha sido deshabilitado, contacta con el administrador.",
        });

        this.logout();

      }

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          await this.setAlert('user_subscription_expired', today, null)

          if (!this.$props.userObject) {
            this.$router.push({ name: 'suscripcion' })
          }
        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true

          let diff = moment(endOfSubscription).diff(today, 'days');
          if (diff <= 5) {

            this.alert = diff;
          }
        }
      } else {

        if (['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(this.user.type) && !this.$props.userObject) {
          this.$router.push({ name: 'suscripcion' })
        }

      }

      let today = moment().toDate()

      //check if user is on an active licsense, for this it needs to check the user 'licensePaused' boolean property.
      if (this.user.licensePaused && !this.$props.userObject) {
        //await this.setAlert('user_license_paused', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia. De lo contrario contacta con el administrador.",
        });
        this.$router.push('/perfil')
      }

      //and also search in the user 'licenses' collection for license between issuedOn and resumedOn datess.
      // Query Firestore for licenses issued before today
      const licensesRef = collection(db, `users/${user}/licenses`);
      const q = query(licensesRef, where("issuedOn", "<", today));

      const licenseDocs = await getDocs(q);
      const filteredLicenses = licenseDocs.docs
        .map(doc => doc.data())
        .filter(license => {
          if (license.resumedOn) {
            return new Date(license.resumedOn.seconds * 1000) > today;
          } else if (typeof license.resumedOn === 'undefined') {
            return true;
          }

        }); // Filter by resumedOn in client

      if (filteredLicenses.length === 0) {
        //console.log('No matching documents.');
      } else {
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia.",
        });
        if (!this.$props.userObject) {
          this.$router.push('/perfil')
        }
      }





    },
  },
};
</script>

<style scoped>
.grid-item2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  color: #FFF;
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  color: #FFF;
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
</style>
